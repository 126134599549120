@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", "Lato", sans-serif;
}

body {
  min-height: 100vh;
  margin: 0;
  /* display: inline; */
  background-color: #e0e9f5;
}

main {
  margin: 1rem auto;
  width: 90%;
  max-width: 90rem;
  text-align: center;
}

h1,
h2,
h3,
p {
  color: #042b5f;
}

a {
  cursor: pointer;
}

.app-container {
  min-height: 350px;
}

.sr-only {
  display: none !important;
}

/* .react-bootstrap-table table {
  table-layout: fixed;
} */

.btn-delete-user {
  width: 60px;
  text-align: center;
  box-sizing: content-box;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  color: whitesmoke;
  background: #0d6efd;
  font-size: 0.8rem;
}
